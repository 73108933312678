import { BoatType, ResultItem } from './ResultItem';
import { useHits } from 'react-instantsearch-hooks-web';
import styles from './CoverSearchbox.module.scss';

export const HitsPanel = () => {
  const { hits } = useHits<BoatType>();

  return (
    <div className={styles.hitsList}>
      {hits.map((hit) => (
        <ResultItem hit={hit} key={hit.__queryID} />
      ))}
    </div>
  );
};
