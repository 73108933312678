import React, { FC } from 'react';
import cn from 'classnames';
import styles from './HomeCover.style.module.scss';
import { HomeCoverAction, HomeCoverProps } from './HomeCover.props';
import HomeCoverActions from './HomeCoverAction';
import { CoverSearchbox } from '@components/CoverSearchbox';

const coverActions: HomeCoverAction[] = [
  {
    label: 'Explore all boats',
    href: '/boats',
    type: 'primary',
  },
];

const HomeCover: FC<HomeCoverProps> = ({ title, subtitle }) => (
  <div className={cn(styles.root)}>
    <div className={cn(styles.coverText)}>
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.subTitle}>{subtitle}</h2>
    </div>
    <CoverSearchbox />
    <HomeCoverActions actions={coverActions} />
    {/* <CoverCarousel /> */}
  </div>
);

export { HomeCover };
