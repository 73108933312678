import { TContent } from 'sailor-ui/BlogUi/PostItemInfo/PostItemInfo.props';
import { useBlogUrl } from '@app/hooks/useBlogUrl';
import { Tag, Typography } from 'antd';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import { PostItemInfo } from 'sailor-ui/BlogUi';
import { GetRecentPostsCards } from 'services/graphql/cms';
import styles from './BlogCard.module.scss';

interface Props {
  post?: GetRecentPostsCards['posts']['data'][number];
}

const BlogCard: FC<Props> = ({ post }) => {
  const { getBlogUrl } = useBlogUrl({});
  const url = useMemo(() => getBlogUrl(post.attributes.slug), [post.attributes.slug, getBlogUrl]);

  return (
    <a href={url} target="_blank" rel="noreferrer" className={`${styles.link}`}>
      <div className={styles.wrapper}>
        <div className={styles.cover}>
          {post?.attributes.featuredImage.data.attributes.url && (
            <Image
              src={post?.attributes.featuredImage.data.attributes.url}
              height="317"
              width="564"
              alt={post?.attributes.featuredImage.data.attributes.alternativeText}
              className={styles.image}
            />
          )}
        </div>
        <div className={styles.dataWrapper}>
          {Array.isArray(post.attributes.post_categories.data) && (
            <div className={styles.categories}>
              {post.attributes.post_categories.data.map((category) => (
                <Tag key={category.attributes.slug} className={styles.tag}>
                  {category.attributes.name}
                </Tag>
              ))}
            </div>
          )}
          <h3 className={styles.title}>{post.attributes.title}</h3>
          <Typography.Paragraph ellipsis={{ rows: 3 }}>
            <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: post.attributes.excerpt }} />
          </Typography.Paragraph>
          <PostItemInfo
            content={post?.attributes.content as TContent[]}
            publishDate={post?.attributes.publishDate}
            showPublishDate={post?.attributes.showPublishDate}
          />
        </div>
      </div>
    </a>
  );
};

export { BlogCard };
