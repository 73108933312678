import React, { ReactNode } from 'react';
import styles from './Section.module.scss';

interface Props {
  bgColor: 'white' | 'gray';
  className?: string;
  children?: ReactNode;
}

const Section: React.FC<Props> = ({ bgColor, children, className }) => (
  <div
    className={`${styles.gridWrapper}
          ${bgColor === 'gray' ? styles.wrapper_gray : styles.wrapper_white}`}
  >
    <div className={`${className || ''} ${styles.gridContainer}`}>{children}</div>
  </div>
);

export default Section;
