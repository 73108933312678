import { ActivityCard } from '@components/ActivityCard';
import SectionTitle from '@components/SectionTitle';
import { Col, Row } from 'antd';
import activities from 'config/activities';
import React from 'react';
import styles from '../../styles/Home.module.scss';
import { activityBoatsHomePageQueryVariables } from 'services/queryClient/defaultQueryVariables';
import { useBoatsActivities } from 'services/hooks/queries/useBoatsActivities';
import { BoatsActivityEntity } from 'services/graphql/cms';

const ActivitySection: React.FC = () => {
  const { data } = useBoatsActivities(activityBoatsHomePageQueryVariables);

  return (
    <>
      {data?.boatsActivities?.data?.length! > 0 && (
        <div className={`${styles.boatsGridWrapper} ${styles.boatsGridWrapper_white}`}>
          <div className={styles.boatsGridContainer}>
            <SectionTitle description="Make the right plans with the perfect boat!" title="Explore by Activity" />
            <Row gutter={24}>
              {data?.boatsActivities?.data?.map((activity) => (
                <Col key={activity?.id} className={styles.boatColItem} sm={12} xs={24} md={8}>
                  <ActivityCard activity={activity as BoatsActivityEntity} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default ActivitySection;
