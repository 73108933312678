import { Typography } from 'antd';
import Image from 'next/image';
import { FC } from 'react';
import { Highlight } from 'react-instantsearch-hooks-web';
import styles from './CoverSearchbox.module.scss';
import { EyeOutlined, HeartFilled } from '@ant-design/icons';
import Link from 'next/link';

export type BoatType = {
  image?: {
    name: string;
    alternativeText: string;
    url?: string;
  };
  model: string;
  slug: string;
  year_first: number;
  favorites_count?: number;
  views_count?: number;
  manufacturer: {
    name: string;
    slug: string;
  };
};

export type BoatHitType = {
  hit: BoatType;
};

const { Title } = Typography;

const ResultItem: FC<BoatHitType> = ({ hit }) => {
  if (!hit.model || !hit.year_first || !hit.manufacturer) {
    return null;
  }

  return (
    <Link href={`/boat/${hit.slug}`} title={`${hit.year_first} ${hit.manufacturer.name} ${hit.model}`}>
      <div className={styles.resultCardWrapper}>
        <div className={styles.resultCardImage}>
          {!!hit?.image?.url ? (
            <Image alt={hit.image.alternativeText || hit.model} src={hit.image.url} width={115} height={65} />
          ) : (
            <div className={styles.noImagePlaceholder}>
              <span>No image</span>
            </div>
          )}
        </div>
        <div className={styles.resultCardText}>
          <Title level={3}>
            <Highlight attribute="year_first" hit={hit as any} />{' '}
            <Highlight attribute="manufacturer.name" hit={hit as any} />{' '}
            <Highlight attribute="model" hit={hit as any} />
          </Title>
          <div className={styles.statistics}>
            <div>
              <EyeOutlined /> {hit.views_count || 0}
            </div>
            <div>
              <HeartFilled /> {hit.favorites_count || 0}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export { ResultItem };
