import React, { FC } from 'react';
import Link from 'next/link';
import { ArrowRightOutlined } from '@ant-design/icons';
import FeaturePreviewProps from './FeaturePreview.props';
import styles from './FeaturePreview.style.module.scss';

const FeaturePreview: FC<FeaturePreviewProps> = ({ title, description, image, imageAlt, buttonLink, buttonText }) => (
  <div className={styles.root}>
    <div className={styles.row}>
      <div className={styles.image}>
        <img width={588} height={610} src={image} alt={imageAlt} />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <Link href={buttonLink} className="ant-btn ant-btn-primary ant-btn-lg">
          <span>{buttonText} </span>
          <ArrowRightOutlined />
        </Link>
      </div>
    </div>
  </div>
);

export { FeaturePreview };
