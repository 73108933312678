import React, { FC } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import styles from './HomeCover.style.module.scss';

type Props = {
  actions: IAction[];
};

interface IAction {
  type: string;
  href: string;
  label: string;
}

const HomeCoverActions: FC<Props> = ({ actions }) => (
  <div className={cn(styles.actions)}>
    {actions.map((action) => {
      const linkClasse = cn(styles.action, 'ant-btn', `ant-btn-${action.type}`, 'ant-btn-lg');

      return (
        <Link href={action.href} key={action.href} className={linkClasse}>
          {action.label}
        </Link>
      );
    })}
  </div>
);

export default HomeCoverActions;
