import { SearchOutlined } from '@ant-design/icons';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { useConfig } from 'providers';
import { FC } from 'react';
import { Configure, Hits, Index, InstantSearch, SearchBox } from 'react-instantsearch-hooks-web';
import styles from './CoverSearchbox.module.scss';
import { CoverSearchboxProps } from './CoverSearchbox.props';
import { ResultItem } from './ResultItem';
import { SearchStatistics } from './SearchStatistics';
import { HitsPanel } from './HitsPanel';

const CoverSearchbox: FC<CoverSearchboxProps> = () => {
  const { meiliSearchApiKey, meiliSearchHostUrl } = useConfig();
  let firstLoad = true;
  const meiliSearchClient = instantMeiliSearch(meiliSearchHostUrl, meiliSearchApiKey, {
    keepZeroFacets: true,
  });

  const searchClient = {
    search(requests) {
      if (firstLoad === true) {
        firstLoad = false;

        return Promise.resolve({
          results: [
            {
              indexUid: 'boat',
              hits: [
                {
                  hits: [],
                },
              ],
            },
          ],
        });
      }

      return meiliSearchClient.search(requests);
    },
  };

  return (
    <div className={styles.coverSearchboxWrapper}>
      <div className={styles.coverSearchbox}>
        <InstantSearch indexName="boat" searchClient={meiliSearchClient}>
          <div className={styles.searchBox}>
            <SearchBox placeholder="Search boat..." submitIconComponent={() => null} />
            <SearchOutlined size={40} />
          </div>
          <Index indexName="boat">
            <HitsPanel />
            <Configure index="boat" key={'id'} />
          </Index>
          <SearchStatistics />
        </InstantSearch>
      </div>
    </div>
  );
};

export { CoverSearchbox };
