import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGetPublishableChecklists, GetPublishableChecklistsVariables } from '../../graphql';
import { useGraphQLClient } from 'providers';

export default function usePublishableChecklists(variables: GetPublishableChecklistsVariables) {
  const { graphQLClient } = useGraphQLClient();

  return useGetPublishableChecklists(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    suspense: false,
  });
}
