import { ArrowRightOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { FC } from 'react';
import styles from './MakerCard.module.scss';

type Props = {
  more: number;
};

const MoreMakersCard: FC<Props> = ({ more }) => (
  <div className={styles.moreCardWrapper}>
    <Card hoverable className={styles.card}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>+ {more} more</h3>
      </div>
    </Card>
  </div>
);

export default MoreMakersCard;
