import styles from './CoverSearchbox.module.scss';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

export const SearchStatistics = () => {
  const { results, status } = useInstantSearch();

  return (
    <div className={styles.searchStatistics}>
      <span>
        {results.nbHits} results in {results.processingTimeMS}ms
      </span>
    </div>
  );
};
