import { GraphQLClient } from 'graphql-request';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { useGetBoatsActivities, GetBoatsActivitiesVariables } from '../../graphql/cms';

export function useBoatsActivities(variables: GetBoatsActivitiesVariables, enabled: boolean = true) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  return useGetBoatsActivities(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnMount: true,
    enabled: enabled,
  });
}
