import { ArrowRightOutlined } from '@ant-design/icons';
import Section from '@components/Section';
import SectionTitle from '@components/SectionTitle';
import React, { useCallback, useState } from 'react';
import { VideoModal, VideoModalState } from 'sailor-ui/VideoModal';

import styles from '../../styles/Home.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { useConfig } from 'providers';
import Link from 'next/link';

const AppsSection: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<VideoModalState>('NOT_OPENED');
  const { isAuthenticated } = useAuth0();
  const { theboatAppUrl, theboatLandingUrl } = useConfig();
  const showVideoModal = (): void => {
    setIsModalVisible('OPEN');
  };

  const closeVideoModal = (): void => {
    setIsModalVisible('CLOSED');
  };

  const theBoatAppiOS = 'https://apps.apple.com/app/id6443831708?platform=iphone';
  const theBoatAppAndroid = 'https://play.google.com/store/apps/details?id=com.marinedatacloud.theboatapp';
  const theBoatAppManagementVideoUrl =
    'https://www.youtube.com/embed/-ReAAleoVHI?autoplay=1&controls=1&enablejsapi=1&html5=1';

  const sectionDescription = `Get <a href="${theboatLandingUrl}" target="_blank"  rel="noreferrer">TheBoatApp </a> 
  and have all of your boating life in one app, fully catalogued, 
  organized and backed-up, accessible from anywhere on any device, 
  online as well as offline.`;

  return (
    <Section bgColor="white">
      <SectionTitle
        description={sectionDescription}
        title="Do you love boats, have one already or plan for one? We got you covered!"
      />
      <div className={`${styles.SectionImage_Wrapper}`}>
        <div className={`${styles.SectionImage}`}>
          <img
            className={styles.SectionImage_Cover}
            src="/images/home-apps-section-cover.jpg"
            height="537px"
            width="956px"
            alt="home-apps-section-cover"
          />
          <button title="Click to Play" type="button" onClick={showVideoModal} className={styles.SectionImage_PlayBtn}>
            <img src="/images/play-btn.svg" alt="play-btn" />
          </button>
        </div>
        <VideoModal
          isModalVisible={isModalVisible}
          closeVideoModal={closeVideoModal}
          src={theBoatAppManagementVideoUrl}
        />
        <div className={`${styles.SectionImage_ActionWrapper}`}>
          <h4>Available for </h4>
          <ul>
            <li>
              <a target="_blank" href={theboatLandingUrl} rel="noreferrer">
                <img src="/images/global-fill.svg" alt="play-btn" />
                <span>Web</span>
              </a>
            </li>
            <li>
              <a href={theBoatAppiOS} target="_blank" rel="noreferrer">
                <img src="/images/apple-fill.svg" alt="play-btn" />
                <span>iOS</span>
              </a>
            </li>
            <li>
              <a href={theBoatAppAndroid} target="_blank" rel="noreferrer">
                <img src="/images/android-fill.svg" alt="play-btn" />
                <span>Android</span>
              </a>
            </li>
          </ul>
        </div>
        <div className={`${styles.boatsGridActionWrapper}`}>
          {!isAuthenticated ? (
            <Link href={'/register'} className="ant-btn ant-btn-primary">
              Get started for free
              <ArrowRightOutlined />
            </Link>
          ) : (
            <a target="_blank" href={theboatAppUrl} className="ant-btn ant-btn-primary" rel="noreferrer">
              Go to TheBoatApp <ArrowRightOutlined />
            </a>
          )}
        </div>
        <p className={`${styles.SectionImage_HintText}`}>FREE Forever - No credit card required</p>
      </div>
    </Section>
  );
};

export default AppsSection;
