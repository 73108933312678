import { cmsGraphQLClient } from '@app/graphQLClient';
import { FeaturePreview } from '@components/FeaturePreview';
import AppsSection from '@components/Home/AppsSection';
import { BlogSection } from '@components/Home/BlogSection';
import { ChecklistSection } from '@components/Home/ChecklistSection';
import ExplorerByBoatTypeSection from '@components/Home/ExplorerByBoatTypeSection';
import ExplorerByHullTypeSection from '@components/Home/ExplorerByHullTypeSection';
import { JoinCommunity } from '@components/Home/JoinCommunity';
import MakersSection from '@components/Home/MakersSection';
import { HomeCover } from '@components/HomeCover';
import { GetStaticProps } from 'next';
import { dehydrate } from '@tanstack/react-query';
import { useGetFeaturedBoats, useGetManufacturers } from 'services/graphql/cms';
import { queryClient } from 'services/queryClient';
import { manufacturersHomePageQueryVariables } from 'services/queryClient/defaultQueryVariables';
import { Layout } from '../components/Layout';
import styles from '../styles/Home.module.scss';
import ActivitySection from '@components/Home/ActivitySection';

const Home: React.FC = () => {
  const metaData = {
    title: 'Boat Specifications Database',
  };

  return (
    <Layout meta={metaData}>
      <div className={styles.wrapper}>
        <HomeCover
          title="Benchmark your boat. Explore & Save"
          subtitle={`Search and compare multiple boats, 
          performance ratios and much more. Add your boat and benchmark it against others.`}
          // eslint-disable-next-line max-len
        />

        {/* <BoatsUsersLoveSection /> */}

        {/* <UserBoatsSection /> */}

        <MakersSection />

        <ActivitySection />

        <ExplorerByBoatTypeSection />

        <ExplorerByHullTypeSection />

        <AppsSection />

        <ChecklistSection />

        <BlogSection />

        <FeaturePreview
          image="/images/home/browser.jpg"
          imageAlt="browser"
          buttonLink="/compare"
          // buttonText="Benchmark your boat for free"
          buttonText="Compare"
          title="Boat Comparison & Benchmarking made easy!"
          description="
            Benchmark your boat versus the “competition” or simply compare side-by-side.
            Spot differences easily among many boats and benchmark performance ratios!
          "
        />

        <JoinCommunity />
      </div>
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async () => {
  await queryClient.prefetchQuery(['getManufacturers', manufacturersHomePageQueryVariables], () =>
    useGetManufacturers.fetcher(cmsGraphQLClient, manufacturersHomePageQueryVariables)(),
  );

  await queryClient.prefetchQuery(['getFeaturedBoats', {}], () => useGetFeaturedBoats.fetcher(cmsGraphQLClient)());

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};
