import { Card } from 'antd';
import Link from 'next/link';
import React, { FC } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { BaseBoatType } from '@interfaces/type';

interface Props {
  type: BaseBoatType;
}

const TypeCard: FC<Props> = ({ type }) => {
  const { Meta } = Card;

  return (
    <Card
      className="TypeCard"
      cover={<img src={type.image_path} height={type.image_height || 50} width={150} alt="type of the boat" />}
    >
      <Meta title={type.title} />
      <Link href={type.link} className="TypeCard-Link TypeCard-Link__Arrow">
        View All
        <RightOutlined className="TypeCard-LinkIcon" />
      </Link>
    </Card>
  );
};

export { TypeCard };
