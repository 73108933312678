import SectionTitle from '@components/SectionTitle';
import { TypeCard } from '@components/TypeCard';
import { Col, Row } from 'antd';
import { boatsTypes } from 'config/types';
import React, { FC } from 'react';
import Section from '@components/Section';
import styles from '../../styles/Home.module.scss';

const ExplorerByBoatTypeSection: FC = () => (
  <Section bgColor="gray">
    <SectionTitle title="Explore by Type" description="Are you a Sail, Power or... SuperSailor?!" />
    <Row gutter={24} className={`${styles.boatColItem} ExplorerByTypeRow`}>
      {boatsTypes.map((type) => (
        <Col key={type.id} className={`${styles.boatColItem} ExplorerByTypeColumn`} sm={12} xs={24} md={8}>
          <TypeCard type={type} />
        </Col>
      ))}
    </Row>
  </Section>
);

export default ExplorerByBoatTypeSection;
