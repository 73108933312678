import { CopyOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import Link from 'next/link';
import { useConfig } from 'providers';
import React from 'react';
import { Checklist } from 'services';
import { CommentIcon } from '../CustomIcons';
import styles from './ChecklistCard.module.scss';
import Image from 'next/image';

type Props = {
  checklist: Checklist;
};

const ChecklistCard: React.FC<Props> = ({ checklist }) => {
  const { theboatAppUrl, theboatLandingUrl } = useConfig();

  const intials = `${checklist?.user?.name ? checklist?.user?.name.charAt(0) : '-'}${
    checklist?.user?.surname ? checklist?.user?.surname?.charAt(0) : '-'
  }`;

  return (
    <div className={styles.card}>
      <Link href={`${theboatLandingUrl}/checklists/${checklist?.id}`} target="_blank" className={styles.cardImage}>
        {checklist?.image?.public_url && (
          <Image
            width="368"
            height="226"
            quality={100}
            src={checklist?.image?.public_url}
            alt={checklist?.image?.alt}
          />
        )}
        {/* <div className={styles.card__share}>
          <ShareAltOutlined />
        </div> */}
      </Link>
      <div className={styles.cardWrapper}>
        <div className={styles.cardContent}>
          <Link href={`${theboatLandingUrl}/checklists/${checklist?.id}`} target="_blank">
            <h3 className={styles.contentTitle}>{checklist?.title}</h3>
          </Link>
          <div
            className={styles.contentText}
            dangerouslySetInnerHTML={{
              __html:
                checklist?.description.length > 100
                  ? checklist?.description.substring(0, 100) + '...'
                  : checklist?.description,
            }}
          />
        </div>

        <div>
          <div className={styles.cardUser}>
            <div className={styles.userInitials}>{intials}</div>
            {checklist?.user?.name} {checklist?.user?.surname}
          </div>

          <div className={styles.cardFooter}>
            <div className={styles.footerLeft}>
              <UnorderedListOutlined /> {checklist?.items?.length || 0}
            </div>
            <ul className={styles.footerRight}>
              <li>
                <CommentIcon /> {checklist?.comments_count || 0}
              </li>
              <li>
                <UserOutlined /> {checklist?.user_followers_count || 0}
              </li>
              <li>
                <CopyOutlined /> {checklist?.clones_count || 0}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ul className={styles.cartLinks}>
        <li className={styles.cartLink}>
          <Link href={`${theboatLandingUrl}/checklists/${checklist?.id}`} target="_blank">
            View
          </Link>
        </li>
        <li className={styles.cartLink}>
          <Link href={`${theboatAppUrl}/checklists/${checklist?.id}`} target="_blank">
            Copy
          </Link>
        </li>
        <li className={styles.cartLink}>
          <Link href={`${theboatAppUrl}/checklists/${checklist?.id}`} target="_blank">
            Follow
          </Link>
        </li>
      </ul>
    </div>
  );
};

export { ChecklistCard };
