import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useConfig } from 'providers';
import { BlogCard } from '@components/BlogCard';
import Section from '@components/Section';
import SectionTitle from '@components/SectionTitle';
import { Button, Skeleton } from 'antd';
import { useKeenSlider } from 'keen-slider/react';
import React, { useState } from 'react';
import { useRecentPostsCards } from 'services/hooks/queries/useRecentPostsCards';
import styles from './BlogSection.module.scss';

const BlogSection: React.FC = () => {
  const { appCode } = useConfig();
  const [currentSlide, setCurrentSlide] = useState(0);

  const [loaded, setLoaded] = useState(false);

  const { data } = useRecentPostsCards({ appCode });

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 1,
      spacing: 0,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 5 },
      },
      '(min-width: 1200px)': {
        slides: { perView: 3, spacing: 10 },
      },
    },
  });

  return (
    <Section className={`${styles.sectionWrapper}`} bgColor="gray">
      <SectionTitle
        title="From our blog"
        description="Dive into a sea of articles about boating, product news and marine personas."
      />
      {data?.posts?.data?.length ? (
        <div className={`${styles.carouselWrapper}`}>
          {loaded && !!instanceRef.current && (
            <ul className={styles.actionsNav}>
              <li>
                <Button
                  shape="circle"
                  className={`${styles.itemNavArrow} ${styles.itemNavArrowLeft}`}
                  onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                  icon={<LeftOutlined />}
                />
              </li>
              <li>
                <Button
                  shape="circle"
                  className={`${styles.itemNavArrow} ${styles.itemNavArrowRight}`}
                  icon={<RightOutlined />}
                  onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                />
              </li>
            </ul>
          )}
          <div ref={ref} className="keen-slider">
            {data?.posts?.data.map((post) => (
              <div className="keen-slider__slide" key={post.id}>
                <BlogCard post={post} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Skeleton active title paragraph />
      )}
    </Section>
  );
};

export { BlogSection };
