import { ArrowRightOutlined } from '@ant-design/icons';
import { MakerCard } from '@components/MakerCard';
import MoreMakersCard from '@components/MakerCard/MoreMakersCard';
import SectionTitle from '@components/SectionTitle';
import { Col, Row } from 'antd';
import Link from 'next/link';
import React, { FC, useMemo } from 'react';
import Section from '@components/Section';
import { useManufacturers } from 'services/hooks/queries/useManufacturers';
import styles from '../../styles/Home.module.scss';
import { manufacturersHomePageQueryVariables } from 'services/queryClient/defaultQueryVariables';

const MANUFACTURERS_QUERY_LIMIT = 14;

const MakersSection: FC = () => {
  const { data } = useManufacturers(manufacturersHomePageQueryVariables);

  const moreMakers = useMemo(() => {
    if (
      data?.manufacturers.meta.pagination.total &&
      data?.manufacturers.meta.pagination.total > MANUFACTURERS_QUERY_LIMIT
    ) {
      return {
        hasMore: true,
        otherTotalMakers: data?.manufacturers.meta.pagination.total - MANUFACTURERS_QUERY_LIMIT,
      };
    }

    return {
      hasMore: false,
      otherTotalMakers: 0,
    };
  }, [data?.manufacturers.meta.pagination.total]);

  return (
    <Section bgColor="gray">
      <SectionTitle title="Explore by Maker" />
      <Row gutter={24}>
        {data?.manufacturers.data.map((maker) => (
          <Col className={styles.boatColItem} key={maker.attributes.lumen_ref_id} sm={12} md={8} xs={24}>
            <Link href={`/maker/${maker.attributes.slug}`}>
              <MakerCard maker={maker} />
            </Link>
          </Col>
        ))}
        {moreMakers.hasMore && (
          <Col className={styles.boatColItem} sm={12} md={8} xs={24}>
            <Link href="/maker">
              <MoreMakersCard more={moreMakers.otherTotalMakers} />
            </Link>
          </Col>
        )}
      </Row>
      <div className={`${styles.boatsGridActionWrapper}`}>
        <Link href="/maker" className="ant-btn ant-btn-primary">
          Explorer by maker
          <ArrowRightOutlined />
        </Link>
      </div>
    </Section>
  );
};

export default MakersSection;
