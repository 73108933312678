import { BaseBoatType } from '@interfaces/type';

export const boatsTypes: BaseBoatType[] = [
  {
    id: '1',
    link: '/boats/type/sail',
    title: 'Sail boats',
    image_path: '/images/type/sail-boats.svg',
    image_height: 70,
  },
  {
    id: '2',
    link: '/boats/type/power',
    title: 'Power boats',
    image_path: '/images/type/power-boats.svg',
  },
  {
    id: '3',
    link: '/boats/category/superyacht-boats',
    title: 'SuperYachts',
    image_path: '/images/type/super-yahchts.svg',
  },
];

export const hullTypes: BaseBoatType[] = [
  {
    id: '1',
    link: '/boats/hull-type/monohull',
    title: 'Monohull',
    image_path: '/images/hull/monohull.svg',
    image_height: 70,
  },
  {
    id: '2',
    link: '/boats/hull-type/catamaran',
    title: 'Catamaran',
    image_path: '/images/hull/catamaran.svg',
    image_height: 70,
  },
  {
    id: '3',
    link: '/boats/hull-type/trimaran',
    title: 'Trimaran',
    image_path: '/images/hull/trimaran.svg',
    image_height: 70,
  },
];
