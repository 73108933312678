import { Card } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import styles from './MakerCard.module.scss';
import { MakerCardProps } from './MakerCard.props';
import Image from 'next/image';

const MakerCard: FC<MakerCardProps> = ({ maker }) => (
  <div>
    <Card hoverable className={styles.card}>
      <div className={styles.image}>
        {maker?.attributes?.logo?.data?.attributes?.url ? (
          <Image
            src={maker?.attributes.logo.data.attributes.url}
            alt={maker?.attributes.logo.data.attributes.name || maker.attributes.name}
            width={170}
            height={112}
          />
        ) : (
          <img src="/images/no-image-placeholder.svg" alt="placeholder" width={170} height={112} />
        )}
      </div>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>{maker.attributes?.name}</h3>
        <ArrowRightOutlined className={styles.icon} />
      </div>
    </Card>
  </div>
);

export { MakerCard };
