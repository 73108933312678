import React from 'react';
import styles from './SectionTitle.module.scss';

type Props = {
  title: string;
  description?: string;
  badge?: string;
};

const SectionTitle: React.FC<Props> = ({ title, description, badge }) => (
  <div className={styles.sectionWrapper}>
    <h2 className={styles.title}>
      <span>{title}</span>
      {badge && badge.length && <span className={styles.badge}>{badge}</span>}
    </h2>
    <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

export default SectionTitle;
