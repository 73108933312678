import { Card, Typography } from 'antd';
import React from 'react';
import Link from 'next/link';
import { RightOutlined } from '@ant-design/icons';
import { BoatsActivityEntity } from 'services/graphql/cms';
import Image from 'next/image';
import styles from './ActivityCard.module.scss';

interface Props {
  activity: BoatsActivityEntity;
}

const { Paragraph } = Typography;

const ActivityCard: React.FC<Props> = ({ activity }) => {
  const { Meta } = Card;

  return (
    <Card
      className={styles.activityCardContainer}
      cover={
        <Image
          width={350}
          height={180}
          src={activity?.attributes?.coverImage?.data?.attributes?.url || '/images/activity/overnight-cruising.jpg'}
          alt={activity?.attributes?.coverImage?.data?.attributes?.name}
        />
      }
    >
      <Meta
        title={activity?.attributes?.name}
        description={
          <Paragraph ellipsis={{ rows: 2 }}>
            <div dangerouslySetInnerHTML={{ __html: activity?.attributes?.description }} />{' '}
          </Paragraph>
        }
      />
      <Link href={`/boats/activity/${activity?.attributes?.slug}`} className={styles.activityCardViewButton}>
        View All
        <RightOutlined className={styles.activityCardIcon} />
      </Link>
    </Card>
  );
};

export { ActivityCard };
