import SectionTitle from '@components/SectionTitle';
import { TypeCard } from '@components/TypeCard';
import { Col, Row } from 'antd';
import { hullTypes } from 'config/types';
import React, { FC } from 'react';
import Section from '@components/Section';
import styles from '../../styles/Home.module.scss';

const ExplorerByHullTypeSection: FC = () => (
  <Section bgColor="gray">
    <SectionTitle
      title="Explore by Hull type"
      description="...some say one is enough, others prefer two and some have even three! Where do you linger?"
    />
    <Row gutter={24} className={`${styles.boatColItem} ExplorerByTypeRow`}>
      {hullTypes.map((type) => (
        <Col key={type.id} className={`${styles.boatColItem} ExplorerByTypeColumn`} sm={12} xs={24} md={8}>
          <TypeCard type={type} />
        </Col>
      ))}
    </Row>
  </Section>
);

export default ExplorerByHullTypeSection;
