import React, { FC } from 'react';
import Link from 'next/link';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import styles from './JoinCommunity.module.scss';

const JoinCommunity: FC = () => {
  const { formatMessage } = useIntl();

  const f = (id: string): string => formatMessage({ id });

  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        <h2 className={styles.content__title}>{f('homepage.community.title')}</h2>
        <p className={styles.content__text}>{f('homepage.community.text')}</p>

        <a
          href="https://support.marinedatacloud.com/portal/en/community/marine-data-cloud"
          className={styles.content__button}
          target="_blank"
          rel="noopener noreferrer"
        >
          {f('homepage.community.button.text')} <ArrowRightOutlined />
        </a>
      </div>

      <div className={styles.container__image}>
        <img src="/images/join-community/person-with-flower-necklace.svg" alt={f('homepage.community.image.alt')} />
      </div>
    </div>
  );
};

export { JoinCommunity };
